import { PortalRequest as request } from '@/utils/request'

/**
 * 根据问卷id获取新问卷答复
 * @param id
 * @returns {*}
 */
export const getNewResponse = (id, userid) => {
  return request({
    url: `questionnaire/${id}/${userid}/response`,
    method: 'get'
  })
}

/**
 * 创建问卷答复
 * @param data
 * @returns {*}
 */
export const createResponse = (data) => {
  return request({
    url: '/response/create',
    method: 'post',
    data
  })
}

/**
 * 调研动态
 * @returns 
 */
export const responseList = (data) => {
  return request({
    url: '/response/list',
    method: 'post',
    data
  })
}

/**
 * 问卷列表
 * @param {*} page 
 * @param {*} data 
 * @returns 
 */
export const getQuestionnaireList = (page,data) => {
  return request({
    url: '/questionnaire/list',
    method: 'post',
    params: page,
    data,
  })
}