<template>
  <div class="questionnaire-box">
    <template v-if="paragraphIndex == 0">
      <div class="questionnaire-banner">
        <v-img
          :attachment-id="form.qstQuestionnaire.questionnaireCoverAttmId"
          :default-img="require('@/assets/img/kctp.png')"
        />
        <span
          v-if="
            form.qstQuestionnaire.enableTitle ||
            form.qstQuestionnaire.enableTitle == null
          "
          >{{ form.qstQuestionnaire.title }}</span
        >
      </div>
      <p class="questionnaire-desc" v-if="form.qstQuestionnaire.description">
        {{ form.qstQuestionnaire.description }}
      </p>
    </template>
    <a-form-model
      :label-col="labelCol"
      :model="form"
      :rules="rules"
      :wrapper-col="wrapperCol"
      ref="responseForm"
    >
      <template
        v-for="(sectionKey, sectionIndex) in form.qstQuestionnaire.qstSections"
      >
        <a-row v-if="sectionIndex == paragraphIndex" :key="sectionIndex">
          <a-col :span="24" class="section">
            <span class="section-title"
              ><b>{{ sectionKey.title }}</b></span
            >
          </a-col>
          <a-row
            v-for="(questionKey, questionIndex) in sectionKey.qstQuestions"
            :key="questionIndex"
          >
            <a-col :span="24" class="question" v-if="questionKey.enableContent">
              <span class="question-title"
                ><em v-if="questionKey.isMandatory" style="color: red">*</em>
                {{ questionKey.index }}、{{ questionKey.content }}</span
              >
            </a-col>
            <a-row>
              <a-col :span="24" class="option">
                <div>
                  <span class="question-title" v-if="!questionKey.enableContent"
                    ><em v-if="questionKey.isMandatory" style="color: red"
                      >*</em
                    >
                    {{ questionKey.index }}、</span
                  >
                  <a-radio-group
                    :name="questionKey.id"
                    @change="selectOptionChange(sectionIndex, questionIndex)"
                    v-if="questionKey.questionType === '1'"
                    v-model="questionKey.selectRadioOption"
                  >
                    <template v-for="optionKey in questionKey.qstOptions">
                      <a-radio :value="optionKey.id" :key="optionKey.id">
                        {{ optionKey.content }}
                        <span v-if="optionKey.isCustomInput == '1'">
                          <a-input
                            class="optionInput fill-blank-inp"
                            placeholder="请注明"
                            v-if="
                              optionKey.id === questionKey.selectRadioOption
                            "
                            v-model="optionKey.customInput"
                          />
                        </span>
                      </a-radio>
                    </template>
                  </a-radio-group>
                  <a-checkbox-group
                    :name="questionKey.id"
                    @change="selectOptionChange(sectionIndex, questionIndex)"
                    v-if="questionKey.questionType === '2'"
                    v-model="questionKey.selectCheckOption"
                  >
                    <template v-for="optionKey in questionKey.qstOptions">
                      <span class="optionInputSpan" :key="optionKey.id">
                        <a-checkbox :value="optionKey.id">
                          {{ optionKey.content }}
                          <span v-if="optionKey.isCustomInput == '1'">
                            <a-input
                              class="optionInput fill-blank-inp"
                              placeholder="请注明"
                              v-if="
                                questionKey.selectCheckOption &&
                                questionKey.selectCheckOption.indexOf(
                                  optionKey.id
                                ) != -1
                              "
                              v-model="optionKey.customInput"
                            />
                          </span>
                        </a-checkbox>
                      </span>
                    </template>
                  </a-checkbox-group>
                  <template v-if="questionKey.questionType === '3'">
                    <template
                      v-for="(
                        fillBlankKey, fillBlankIndex
                      ) in questionKey.qstFillBlanks"
                    >
                      {{ fillBlankKey.leftContent }}
                      <a-input
                        :key="fillBlankIndex"
                        class="fill-blank-inp"
                        placeholder="请输入"
                        :style="{
                          'min-width': '80px',
                          width:
                            fillBlankKey.customInput != null
                              ? fillBlankKey.customInput.length * 16 + 'px'
                              : '80px',
                          'max-width': '200px',
                        }"
                        v-model="fillBlankKey.customInput"
                      />
                      {{ fillBlankKey.rightContent }}
                      <template v-if="fillBlankKey.splitType=='2'">;</template>
                      <template v-if="fillBlankKey.splitType=='3'"><br :key="fillBlankIndex"/></template>
                    </template>
                  </template>
                </div>
              </a-col>
            </a-row>
          </a-row>
        </a-row>
      </template>
      <template v-if="paragraphIndex + 1 == paragraphNum">
        <a-row>
          <a-col :span="24" class="section">
            <span class="section-title"><b>填报人信息</b></span>
          </a-col>
        </a-row>
        <a-form-model-item
          label="填表单位"
          prop="submitUnitBy"
          ref="submitUnitBy"
        >
          <a-input placeholder="请填写填表单位" v-model="form.submitUnitBy" />
        </a-form-model-item>
        <a-form-model-item label="填表人" prop="submitBy" ref="submitBy">
          <a-input placeholder="请填写填表人" v-model="form.submitBy" />
        </a-form-model-item>
        <a-form-model-item label="填表职位" prop="position" ref="position">
          <a-input placeholder="请填写职位" v-model="form.position" />
        </a-form-model-item>
        <a-form-model-item label="联系信息" prop="contact" ref="contact">
          <a-input placeholder="请填写联系信息" v-model="form.contact" />
        </a-form-model-item>
        <a-form-model-item label="选择地区" prop="address" ref="address">
          <area-select
            placeholder="选择地区"
            v-model="form.address"
            :default-value="form.address"
          />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14 }">
          <a-button
            @click="handleSubmit"
            class="btn-red"
            v-if="form.qstQuestionnaire.publishStatus != 3"
            style="width: 100%"
            >提交</a-button
          >
        </a-form-model-item>
      </template>
      <a-form-model-item v-if="paragraphIndex != 0" :wrapper-col="{ span: 14 }">
        <a-button @click="lastQuestion" class="btn-red" style="width: 100%">上一步</a-button>
      </a-form-model-item>
      <a-form-model-item v-if="paragraphIndex + 1 < paragraphNum" :wrapper-col="{ span: 14 }">
        <a-button @click="nextQuestion" class="btn-red" style="width: 100%">下一步</a-button>
      </a-form-model-item>
    </a-form-model>
    <share :val="shareInfo"/>
  </div>
</template>

<script>
import { getNewResponse, createResponse } from "@/api/questionnaire";
import areaSelect from "./areaselect";
import VImg from "@/components/VImg";
import share from '../components/share.vue'
export default {
  components: {
    VImg,
    areaSelect,
    share
  },
  name: "responseForm",
  data() {
    return {
      labelCol: { span: 2 },
      wrapperCol: { span: 10 },
      paragraphIndex: 0,
      paragraphNum: 1,
      form: {
        submitUnitBy: null,
        submitBy: null,
        position: null,
        contact: null,
        qstQuestionnaire: {},
        address: [],
      },
      rules: {
        submitUnitBy: [
          {
            required: true,
            message: "请输入填表单位",
            trigger: "blur",
          },
        ],
        submitBy: [
          {
            required: true,
            message: "请输入填表人",
            trigger: "blur",
          },
        ],
        position: [
          {
            required: true,
            message: "请输入职位",
            trigger: "blur",
          },
        ],
        contact: [
          {
            required: true,
            message: "请输入联系信息",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "选择地区",
            trigger: "change",
          },
        ],
      },
      shareInfo: {
          title: '',
          desc: '',
          img: require('@/assets/img/logoinimg.png')
      }
    };
  },
  computed: {
    id: function () {
      return this.$route.query.id;
    },
    userid() {
      return this.$route.query.userId;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    lastQuestion(){
      let { paragraphIndex } = this;
      this.paragraphIndex = paragraphIndex - 1;
      window.scrollTo(0,0)
    },
    nextQuestion() {
      let { paragraphIndex, form } = this;
      let index = paragraphIndex;
      let obj = form.qstQuestionnaire.qstSections;
      for (let e = 0; e < obj[index].qstQuestions.length; e++) {
        if (obj[index].qstQuestions[e].isMandatory) {
          if (obj[index].qstQuestions[e].questionType == "1") {
            if (
              obj[index].qstQuestions[e].selectRadioOption == null ||
              obj[index].qstQuestions[e].selectRadioOption == ""
            ) {
              this.$notify({
                type: "warning",
                message: `请选择第${obj[index].qstQuestions[e].index}题`,
              });
              return false;
            }
          } else if (obj[index].qstQuestions[e].questionType == "2") {
            if (
              obj[index].qstQuestions[e].selectCheckOption == null ||
              obj[index].qstQuestions[e].selectCheckOption == ""
            ) {
              this.$notify({
                type: "warning",
                message: `请选择第${obj[index].qstQuestions[e].index}题`,
              });
              return false;
            }
          } else if (obj[index].qstQuestions[e].questionType == "3") {
            for (
              let j = 0;
              j < obj[index].qstQuestions[e].qstFillBlanks.length;
              j++
            ) {
              if (
                obj[index].qstQuestions[e].qstFillBlanks[j].customInput ==
                  null ||
                obj[index].qstQuestions[e].qstFillBlanks[j].customInput == ""
              ) {
                this.$notify({
                  type: "warning",
                  message: `请输入第${obj[index].qstQuestions[e].index}题填空`,
                });
                return false;
              }
            }
          }
        }
      }
      this.paragraphIndex = paragraphIndex + 1;
      window.scrollTo(0,0)
    },
    loadData() {
      getNewResponse(this.id, this.userid).then((res) => {
        let index = 1;
        this.paragraphNum = res.qstQuestionnaire.qstSections.length;
        res.qstQuestionnaire.qstSections.forEach((item) => {
          item.qstQuestions.forEach((questionItem) => {
            questionItem.index = index;
            index++;
          });
        });
        this.form = res;
        this.shareInfo.title = res.qstQuestionnaire.title
        this.shareInfo.desc = res.qstQuestionnaire.description
        this.shareInfo.img = `${process.env.VUE_APP_OBS_PREFIX}/${res.qstQuestionnaire.questionnaireCoverAttmId}`
        if (res.qstQuestionnaire.publishStatus == 3) {
          this.$notify({ type: "warning", message: `该问卷已结束！` });
        }
      });
    },
    handleSubmit() {
      this.doSave();
    },
    doSave() {
      const { form } = this;
      let _this = this;
      this.$refs.responseForm.validate((valid) => {
        if (valid) {
          form.address = form.address.toString();
          createResponse({
            ...form,
          }).then(() => {
            _this.$notify({
              type: "success",
              duration: 1500,
              message: "提交成功！",
            });
            setTimeout(() => {
              _this.$router.push(`/questionnaire`);
            }, 1500);
          });
        } else {
          return false;
        }
      });
    },
    selectOptionChange(sectionIndex, questionIndex) {
      if (
        this.form.qstQuestionnaire.qstSections[sectionIndex].qstQuestions[
          questionIndex
        ].qstOptions
      ) {
        this.form.qstQuestionnaire.qstSections[sectionIndex].qstQuestions[
          questionIndex
        ].qstOptions.forEach((option) => {
          if (option.hasSubQuestion) {
            if (
              this.form.qstQuestionnaire.qstSections[sectionIndex].qstQuestions[
                questionIndex
              ].selectRadioOption == option.id
            ) {
              this.form.qstQuestionnaire.qstSections[sectionIndex].qstQuestions[
                questionIndex
              ].isShow = true;
            } else if (
              this.form.qstQuestionnaire.qstSections[sectionIndex].qstQuestions[
                questionIndex
              ].selectCheckOption &&
              this.form.qstQuestionnaire.qstSections[sectionIndex].qstQuestions[
                questionIndex
              ].selectCheckOption.indexOf(option.id) != -1
            ) {
              this.form.qstQuestionnaire.qstSections[sectionIndex].qstQuestions[
                questionIndex
              ].isShow = true;
            } else {
              this.form.qstQuestionnaire.qstSections[sectionIndex].qstQuestions[
                questionIndex
              ].isShow = false;
            }
          }
        });
      }
    },
    //选择地区之后的回调
    selectArea(selectedArea) {
      this.form.address = selectedArea;
    },
  },
};
</script>

<style scoped>
.questionnaire-banner {
  position: relative;
  margin-bottom: 20px;
}
.questionnaire-banner > img {
  width: 100%;
  height: 150px;
  display: block;
}
.questionnaire-banner > span {
  display: block;
  position: absolute;
  z-index: 2;
  font-size: 24px;
  color: #313a63;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 16px;
  text-align: center;
  line-height: 34px;
}
.questionnaire-desc {
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 24px;
}
.questionnaire-box {
  padding: 16px;
  color: #333;
  overflow: hidden;
}
.top {
  text-align: center;
}

.title {
  font-size: 20px;
  color: #333;
  margin-bottom: 20px;
  display: block;
  line-height: 20px;
}

.section {
  font-size: 16px;
  line-height: 16px;
  color: #333;
  margin-bottom: 14px;
}

.question {
  margin: 0 0 10px 0;
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
}

.option {
  margin-bottom: 20px;
  color: #333;
  line-height: 24px;
}

.optionInputSpan {
  white-space: nowrap;
  display: inline-block;
}

.optionInput {
  width: 200px;
}
.questionnaire-box .ant-radio-wrapper,
.questionnaire-box .optionInputSpan {
  white-space: initial;
  display: block;
  margin: 0 0 6px 0;
}
.questionnaire-box .ant-radio-wrapper span,
.questionnaire-box .ant-checkbox-wrapper span {
  box-sizing: border-box;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
  margin-right: 10px;
}
.fill-blank-inp {
  border-radius: 0;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  padding: 0 2px;
}
</style>
<style>
.ant-radio-checked .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked::after,
.ant-input:hover {
  border-color: #EA0B06;
}
.ant-radio-inner::after {
  background-color: #EA0B06;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #EA0B06;
  border-color: #EA0B06;
}
</style>
